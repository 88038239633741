<template>
  <a-modal
    centered
    cancelText="Закрыть"
    okText="Сохранить"
    :title="object.info.name"
    :closable="false"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleClose"
  >
    <div class="ant-modal-body-content">
      <a-form-model ref="editObjectForm" :model="form">
        <template v-if="activitiModalEdit">
          <a-form-model-item
            ref="status"
            label="Выберите статус *:"
            prop="status"
          >
            <a-select
              v-model="form.status"
              size="large"
              placeholder="Выберите статус"
            >
              <a-select-option
                v-for="option in statuses"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            ref="manager"
            label="Выберите менеджера *:"
            prop="manager"
          >
            <a-select
              v-model="form.manager"
              size="large"
              placeholder="Выберите менеджера"
            >
              <a-select-option
                v-for="option in managers"
                :key="option.id"
                :value="option.id"
              >
                {{ option.full }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </template>

        <a-form-model-item
          ref="action"
          label="Выберите вид активности *:"
          prop="action"
        >
          <a-select
            v-model="form.action"
            size="large"
            placeholder="Выберите активность"
          >
            <a-select-option value="Звонок"> Звонок </a-select-option>
            <a-select-option value="Посещение"> Посещение </a-select-option>
            <a-select-option value="Отгрузка"> Отгрузка </a-select-option>
            <a-select-option value="Операционка"> Операционка </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          v-if="form.action === 'Отгрузка'"
          ref="direction"
          label="Выберите направление работы *:"
          prop="direction"
        >
          <a-select
            v-model="form.direction"
            size="large"
            placeholder="Выберите направления *"
          >
            <a-select-option
              v-for="option in object.directions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          v-if="activitiModalEdit"
          ref="date"
          label="Выберите дату:"
          prop="date"
        >
          <a-date-picker
            style="width: 100%"
            size="large"
            format="DD/MM/YYYY"
            placeholder="Выберите дату"
            :default-value="form.date"
            @change="onChangeDate"
          ></a-date-picker>
        </a-form-model-item>

        <a-form-model-item
          v-else
          ref="direction"
          label="Выберите дату:"
          prop="direction"
        >
          <a-date-picker
            style="width: 100%"
            size="large"
            format="DD/MM/YYYY"
            :value="form.dateMoment ? form.dateMoment : moment()"
            :disabled-date="disabledDate"
            @change="onChangeDate"
          />
        </a-form-model-item>

        <a-form-model-item label="Оставьте комментарий *:" prop="comment">
          <a-input
            v-model="form.comment"
            type="textarea"
            placeholder="Комментарий"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import apiRequest from '../js/helpers/apiRequest.js';

export default {
  name: 'ActivitiModal',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    activitiModalEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      confirmLoading: false,
      form: {
        status: undefined,
        manager: undefined,
        action: undefined,
        direction: undefined,
        date: '',
        dateMoment: '',
        comment: '',
      },
    };
  },

  watch: {
    activitiModalEdit(isEdit) {
      if (isEdit) {
        const {
          form,
          object: { lastStatus },
        } = this;

        const data = lastStatus[0];

        if (data.statusId !== 4 && data.statusId !== 0) {
          form.status = data.statusId;
        }

        if (data.managerId) {
          form.manager = data.managerId;
        }

        if (data.action !== '-') {
          form.action = data.action;
        }

        if (data.directionId) {
          form.direction = data.directionId;
        }

        form.comment = data.comment;

        // form.date = moment(data.dateAt);
      }
    },
  },

  computed: {
    ...mapState({
      userRole: (state) => state.user.info.role,
      object: (state) => state.objects.object,
      statuses: (state) =>
        state.filters.statuses.filter(
          (status) => status.id !== 0 && status.id !== 4
        ),
      managers: (state) => state.managers.managers,
      // directions: (state) => state.filters.directions
    }),
  },

  methods: {
    moment,

    handleClose() {
      this.$emit('close');

      const { form } = this;
      form.status = undefined;
      form.manager = undefined;
      form.action = undefined;
      form.direction = undefined;
      form.date = '';
      form.dateMoment = '';
      form.comment = '';
    },

    disabledDate(current) {
      return (
        current < moment().subtract(3, 'days') ||
        current > moment().add(0, 'days')
      );
    },

    onChangeDate(date, dateString) {
      this.form.date = dateString;
      this.form.dateMoment = date;
    },

    async handleSave() {
      try {
        const body = new FormData();
        const objectId = this.$route.params.id;
        const {
          activitiModalEdit,
          form: { status, manager, action, direction, date, comment },
        } = this;

        if (
          !action ||
          (action === 'Отгрузка' && !direction) ||
          !comment ||
          (activitiModalEdit && !manager)
        ) {
          this.$notification.warning({
            message: 'Ошибка',
            description: 'Заполните все обязательные поля',
          });
        } else {
          if (activitiModalEdit) {
            body.append('object_id', objectId);
          } else {
            body.append('id', objectId);
          }
          body.append('activity', action);

          if (activitiModalEdit) {
            body.append('status', status);
            body.append('user_id', manager);
          }

          if (action === 'Отгрузка') {
            body.append('direction', direction);
          }

          if (date) {
            body.append('date_at', date);
          }

          body.append('comment', comment);

          this.confirmLoading = true;
          let res = null;

          if (activitiModalEdit) {
            res = await apiRequest('/object/history/edit', 'POST', body, true);
          } else {
            res = await apiRequest('/object/edit-activity', 'POST', body, true);
          }

          if (res.error) {
            this.confirmLoading = false;
            this.$notification.warning({
              message: 'Ошибка',
              description: res.error,
            });
          } else {
            await this.getObjectInfo(objectId);
            this.confirmLoading = false;
            this.handleClose();
          }
        }
      } catch (error) {
        console.log('handleSave:', error);
        this.confirmLoading = false;
        this.$notification.warning({
          message: 'Ошибка',
          description: 'Что-то пошло не так..',
        });
      }
    },

    ...mapActions({
      getObjectInfo: 'objects/getObjectInfo',
    }),
  },
};
</script>

<style lang="scss"></style>

<template>
  <a-modal
    centered
    okText="Сохранить"
    cancelText="Закрыть"
    :title="object.info.name"
    :width="750"
    :closable="false"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleClose"
  >
    <div class="ant-modal-body-content">
      <a-form-model ref="profileForm" class="page-profile-form" :model="form">
        <a-form-model-item ref="name" label="Название объекта" prop="name">
          <a-input
            size="large"
            placeholder="Название"
            v-model="form.name"
            :disabled="userRole === 'manager'"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item ref="region" label="Область" prop="region">
          <a-select
            v-model="form.region"
            size="large"
            placeholder="Выберите область"
            :disabled="userRole === 'manager'"
          >
            <a-select-option
              v-for="option in regions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="district" label="Район" prop="district">
          <a-select
            v-model="form.district"
            size="large"
            placeholder="Выберите район"
            :disabled="userRole === 'manager'"
          >
            <a-select-option
              v-for="option in districts"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="address" label="Адрес объекта" prop="address">
          <a-input
            size="large"
            placeholder="Адрес"
            v-model="form.address"
            :disabled="userRole === 'manager'"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
          ref="comment"
          label="Комментарий к объекту"
          prop="comment"
        >
          <a-input
            type="textarea"
            size="large"
            placeholder="Комментарий"
            v-model="form.comment"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item ref="unp" label="УНП объекта" prop="unp">
          <a-input size="large" placeholder="УНП" v-model="form.unp"></a-input>
        </a-form-model-item>

        <a-form-model-item
          ref="swonareaText"
          label="Посевные площади хозяйства"
          prop="swonareaText"
        >
          <a-input
            size="large"
            placeholder="Общая площадь в Га"
            v-model="form.swonareaText"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item ref="managers" label="Менеджеры *" prop="managers">
          <a-select
            v-model="form.managers"
            mode="multiple"
            size="large"
            placeholder="Выберите менеджеров"
          >
            <a-select-option
              v-for="option in managers"
              :key="option.id"
              :value="option.id"
            >
              {{ `${option.name} ${option.lastName}` }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          ref="directions"
          label="Направления работы *"
          prop="directions"
        >
          <a-select
            v-model="form.directions"
            mode="multiple"
            size="large"
            placeholder="Выберите направления"
          >
            <a-select-option
              v-for="option in directions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          v-if="userRole === 'admin'"
          ref="active"
          label="Объект активен"
          prop="active"
        >
          <a-switch :checked="form.active" @change="onChangeAvtiveStatus" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-divider></a-divider>

    <div class="ant-modal-body-content">
      <div class="object-layout-info-title mt-0 mb-10">Контактные лица:</div>
    </div>

    <div class="contacts-table">
      <a-table
        bordered
        :columns="columns"
        :data-source="contacts"
        :pagination="false"
      >
        <template
          v-for="col in ['fio', 'position', 'phone', 'email']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="
                (e) => handleChangeContact(e.target.value, record.key, col)
              "
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <template v-if="record.editable">
              <button
                class="operation-btn operation-btn-save"
                @click="save(record.key)"
              >
                <a-icon type="check-square"></a-icon>
              </button>
              <button
                class="operation-btn operation-btn-cancel"
                @click="cancel(record.key)"
              >
                <a-icon type="close-square"></a-icon>
              </button>
            </template>

            <template v-else>
              <button
                class="operation-btn operation-btn-edit"
                :disabled="editingKey !== ''"
                @click="edit(record.key)"
              >
                <a-icon type="form"></a-icon>
              </button>

              <button
                class="operation-btn operation-btn-delete"
                :disabled="editingKey !== ''"
                @click="onDelete(record.key)"
              >
                <a-icon type="delete"></a-icon>
              </button>
            </template>
          </div>
        </template>
      </a-table>
    </div>

    <div class="ant-modal-body-content mt-15">
      <a-button @click="addRow">Добавить контакт</a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import apiRequest from '../js/helpers/apiRequest.js';

const columns = [
  {
    title: 'ФИО',
    dataIndex: 'fio',
    scopedSlots: { customRender: 'fio' },
  },
  {
    title: 'Должность',
    dataIndex: 'position',
    scopedSlots: { customRender: 'position' },
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    scopedSlots: { customRender: 'email' },
  },
  {
    title: '',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  name: 'EditModal',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      confirmLoading: false,
      contacts: [],
      columns,
      editingKey: '',

      form: {
        name: '',
        region: undefined,
        district: undefined,
        address: '',
        comment: '',
        unp: '',
        swonareaText: '',
        managers: [],
        directions: [],
        active: false,
      },
    };
  },

  computed: {
    ...mapState({
      userRole: (state) => state.user.info.role,
      object: (state) => state.objects.object,
      managers: (state) => state.managers.managers,
      regions: (state) => state.filters.regions,
      districts: (state) => state.filters.districts,
      directions: (state) => state.filters.directions,
    }),
  },

  created() {
    const {
      form,
      object: { info, managers, directions, contacts },
    } = this;

    form.name = info.name;
    form.region = Number(info.region);
    form.district = info.districtId;
    form.address = info.address;
    form.comment = info.comment;
    form.unp = info.unp;
    form.swonareaText = info.swonareaText;
    form.active = info.status !== 0;
    managers.forEach((item) => {
      form.managers.push(item.id);
    });
    directions.forEach((item) => {
      form.directions.push(item.id);
    });

    this.contacts = JSON.parse(JSON.stringify(contacts));
    this.cacheСontacts = contacts.map((item) => ({ ...item }));
  },

  methods: {
    onChangeAvtiveStatus(val) {
      this.form.active = val;
    },

    async handleSave() {
      try {
        const {
          form: {
            name,
            region,
            district,
            address,
            comment,
            unp,
            swonareaText,
            managers,
            directions,
            active,
          },
          object: {
            info: { status },
          },
          contacts,
        } = this;
        const objectId = this.$route.params.id;
        const body = new FormData();

        body.append('id', objectId);
        body.append('name', name);
        body.append('address', address);
        body.append('comment', comment);
        body.append('region', region);
        body.append('district_id', district);
        body.append('unp', unp);
        body.append('swonarea_text', swonareaText);

        if (status === 0 && active) {
          body.append('status', 4);
        } else if (status !== 0 && !active) {
          body.append('status', 0);
        } else {
          body.append('status', status);
        }

        managers.forEach((item) => {
          body.append('managers[]', item);
        });

        directions.forEach((item) => {
          body.append('directions[]', item);
        });

        body.append('contacts', JSON.stringify(contacts));

        this.confirmLoading = true;
        const res = await apiRequest('/object/edit', 'POST', body, true);

        if (res.object) {
          await this.getObjectInfo(objectId);
          this.confirmLoading = false;
          this.handleClose();
        }

        console.log(res);
      } catch (error) {
        console.log('handleSave:', error);
        this.confirmLoading = false;
        this.$notification.warning({
          message: 'Ошибка',
          description: 'Заполните все поля помеченные *',
        });
      }
    },

    handleClose() {
      this.$emit('close');
    },

    handleChangeContact(value, key, column) {
      const newData = [...this.contacts];
      const target = newData.filter((item) => key === item.key)[0];

      if (target) {
        target[column] = value;
        this.contacts = newData;
      }
    },

    edit(key) {
      const newСontacts = [...this.contacts];
      const target = newСontacts.filter((item) => key === item.key)[0];

      this.editingKey = key;

      if (target) {
        target.editable = true;
        this.contacts = newСontacts;
      }
    },

    onDelete(key) {
      const contacts = [...this.contacts];
      this.contacts = contacts.filter((item) => item.key !== key);
    },

    save(key) {
      const newСontacts = [...this.contacts];
      const newСontactsData = [...this.cacheСontacts];
      const target = newСontacts.filter((item) => key === item.key)[0];
      const targetCache = newСontactsData.filter((item) => key === item.key)[0];

      console.log(targetCache, 'targetCache');

      if (target && targetCache) {
        delete target.editable;
        this.contacts = newСontacts;
        Object.assign(targetCache, target);
        this.cacheСontacts = newСontactsData;
      }

      this.editingKey = '';
    },

    cancel(key) {
      const newСontacts = [...this.contacts];
      const target = newСontacts.filter((item) => key === item.key)[0];

      this.editingKey = '';

      if (target) {
        Object.assign(
          target,
          this.cacheСontacts.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.contacts = newСontacts;
      }
    },

    addRow() {
      const { contacts } = this;
      const newContacts = {
        key: contacts.length + 1,
        fio: '',
        position: '',
        phone: '',
        email: '',
      };

      this.contacts = [...contacts, newContacts];
      this.cacheСontacts = [...contacts, newContacts];
    },

    ...mapActions({
      getObjectInfo: 'objects/getObjectInfo',
    }),
  },
};
</script>

<style lang="scss">
.operation-btn {
  border: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  background-color: transparent;
  outline: none !important;

  + .operation-btn {
    margin-left: 5px;
  }

  i {
    display: block;
    font-size: 20px;
  }
}

.editable-row-operations {
  display: flex;
  justify-content: center;
}

.contacts-table {
  .ant-table-thead {
    th[key='fio'] {
      min-width: 175px;
    }

    th[key='position'] {
      min-width: 175px;
    }

    th[key='phone'],
    th[key='email'] {
      min-width: 150px;
    }

    th[key='operation'] {
      min-width: 80px;
    }
  }
}
</style>

<template>
  <div class="page page-object">
    <a-row v-if="object.info">
      <a-col :lg="{ span: 18 }">
        <div class="page-object-info">
          <div class="page-object-info-name">{{ object.info.name }}</div>
          <div
            v-if="object.info.comment !== 'null' && object.info.comment"
            class="page-object-info-comment"
          >
            {{
              object.info.comment !== 'null' && object.info.comment
                ? object.info.comment
                : ''
            }}
          </div>

          <a-row class="mt-25">
            <a-col :span="12">
              <div class="dashboard-list-item-title">Область:</div>
              <div class="dashboard-list-item-address">
                {{
                  regions.find((region) => region.id == object.info.region).name
                }}
              </div>
            </a-col>

            <a-col :span="12">
              <div class="dashboard-list-item-title">Район:</div>
              <div class="dashboard-list-item-address">
                {{ object.info.district }}
              </div>
            </a-col>
          </a-row>

          <div class="object-layout-info-title">Адрес:</div>
          <div>
            {{ object.info.address }}
          </div>

          <template v-if="object.info.unp">
            <div class="object-layout-info-title">УНП:</div>
            <div>
              {{ object.info.unp }}
            </div>
          </template>

          <template>
            <div class="object-layout-info-title">Комментарий к объекту:</div>
            <div>
              {{
                object.info.comment !== 'null' && object.info.comment
                  ? object.info.comment
                  : '-'
              }}
            </div>
          </template>

          <template v-if="object.info.swonareaText">
            <div class="object-layout-info-title">
              Посевные площади хозяйства (Га):
            </div>
            <div>
              {{ object.info.swonareaText }}
            </div>
          </template>
        </div>
      </a-col>

      <a-col :lg="{ span: 6 }">
        <div class="object-layout-actions">
          <a-button @click="toggleEditModal"> Редактировать объект </a-button>

          <a-button @click="toggleActivitiModalVisible">
            Изменить статус
          </a-button>

          <a-popconfirm
            :title="`Вы уверены что хотите удалить объект?`"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="handleRemoveObject"
          >
            <a-button type="danger" ghost>Удалить объект</a-button>
          </a-popconfirm>
        </div>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="24">
        <div class="object-layout-info-title">Посевные площади объекта:</div>

        <div>
          <a-button @click="addRow" :loading="addRowLoading">Добавить</a-button>
        </div>
        <a-spin :spinning="addRowLoading">
          <div class="swon-area-table">
            <a-table
              :columns="columns"
              :data-source="swonAreas"
              :pagination="false"
              tableLayout="auto"
              class="mt-10"
            >
              <template
                v-for="col in [
                  'culture',
                  'square',
                  'manufacturer',
                  'provider',
                  'comment',
                ]"
                :slot="col"
                slot-scope="text, record"
              >
                <div v-if="col !== 'culture'" :key="col">
                  <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    @change="
                      (e) =>
                        handleChangeSwonArea(e.target.value, record.key, col)
                    "
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>

                <div v-else :key="col">
                  <a-select
                    style="width: 100%"
                    v-if="record.editable"
                    @change="
                      (val) => handleChangeSwonArea(val, record.key, col)
                    "
                  >
                    <a-select-option
                      v-for="culture in cultures"
                      :key="culture.id"
                      :value="culture.id"
                    >
                      {{ culture.name }}
                    </a-select-option>
                  </a-select>
                  <template v-else>
                    {{ cultures.find((cultur) => cultur.id == text).name }}
                  </template>
                </div>
              </template>

              <template slot="operation" slot-scope="text, record">
                <div class="editable-row-operations">
                  <template v-if="record.editable">
                    <button
                      class="operation-btn operation-btn-save"
                      @click="save(record.key)"
                    >
                      <a-icon type="check-square"></a-icon>
                    </button>
                    <button
                      class="operation-btn operation-btn-cancel"
                      @click="cancel(record.key)"
                    >
                      <a-icon type="close-square"></a-icon>
                    </button>
                  </template>

                  <template v-else>
                    <button
                      class="operation-btn operation-btn-edit"
                      :disabled="editingKey !== ''"
                      @click="edit(record.key)"
                    >
                      <a-icon type="form"></a-icon>
                    </button>

                    <button
                      class="operation-btn operation-btn-delete"
                      :disabled="editingKey !== ''"
                      @click="onDelete(record.key)"
                    >
                      <a-icon type="delete"></a-icon>
                    </button>
                  </template>
                </div>
              </template>
            </a-table>
          </div>
        </a-spin>
      </a-col>
    </a-row>

    <template v-if="object.info">
      <edit-modal
        :visible="isEditModalVisible"
        @close="toggleEditModal"
      ></edit-modal>
      <activiti-modal
        :visible="isActivitiModalVisible"
        @close="toggleActivitiModalVisible"
      ></activiti-modal>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import apiRequest from '../js/helpers/apiRequest';

import ActivitiModal from '../components/ActivitiModal.vue';
import EditModal from '../components/EditModal.vue';

const columns = [
  {
    title: 'Культура',
    dataIndex: 'culture',
    key: 'culture',
    width: 225,
    scopedSlots: { customRender: 'culture' },
  },
  {
    title: 'Га',
    dataIndex: 'square',
    key: 'square',
    width: 100,
    scopedSlots: { customRender: 'square' },
  },
  {
    title: 'Производитель',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
    scopedSlots: { customRender: 'manufacturer' },
  },
  {
    title: 'Поставщик',
    dataIndex: 'provider',
    key: 'provider',
    scopedSlots: { customRender: 'provider' },
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    scopedSlots: { customRender: 'comment' },
  },
  {
    title: '',
    dataIndex: 'operation',
    width: 80,
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  name: 'ObjectSownArea',

  components: {
    ActivitiModal,
    EditModal,
  },

  data() {
    return {
      columns,
      swonAreas: [],
      editingKey: '',
      isEditModalVisible: false,
      isActivitiModalVisible: false,
      addRowLoading: false,
      deleteObjectLoading: false,
    };
  },

  watch: {
    object(val) {
      this.swonAreas = JSON.parse(JSON.stringify(val.swonAreas));
      this.cacheSwonAreas = val.swonAreas.map((item) => ({ ...item }));
    },
  },

  computed: {
    ...mapState({
      object: (state) => state.objects.object,
      cultures: (state) => state.filters.cultures,
      regions: (state) => state.filters.regions,
    }),
  },

  created() {
    const {
      object: { swonAreas },
    } = this;

    if (swonAreas) {
      this.swonAreas = JSON.parse(JSON.stringify(swonAreas));
      this.cacheSwonAreas = swonAreas.map((item) => ({ ...item }));
    }
  },

  methods: {
    toggleEditModal() {
      this.isEditModalVisible = !this.isEditModalVisible;
    },

    toggleActivitiModalVisible() {
      this.isActivitiModalVisible = !this.isActivitiModalVisible;
    },

    handleChangeSwonArea(value, key, column) {
      console.log(value, key, column);
      const newData = [...this.swonAreas];
      const target = newData.filter((item) => key === item.key)[0];

      if (target) {
        target[column] = value;
        this.swonAreas = newData;
      }
    },

    edit(key) {
      const newSwonAreas = [...this.swonAreas];
      const target = newSwonAreas.filter((item) => key === item.key)[0];

      this.editingKey = key;

      if (target) {
        target.editable = true;
        this.swonAreas = newSwonAreas;
      }
    },

    async onDelete(key) {
      const swonAreas = [...this.swonAreas];
      const isLocalItem = swonAreas.find((item) => item.key === key).locale;
      this.swonAreas = swonAreas.filter((item) => item.key !== key);

      if (!isLocalItem) {
        const body = new FormData();
        body.append('id', key);

        const res = await apiRequest(
          '/object/square/delete',
          'POST',
          body,
          true
        );
        console.log(res);
      }
    },

    save(key) {
      console.log(key);
      const newSwonAreas = [...this.swonAreas];
      const newSwonAreasData = [...this.cacheSwonAreas];
      const target = newSwonAreas.filter((item) => key === item.key)[0];
      const targetCache = newSwonAreasData.filter(
        (item) => key === item.key
      )[0];

      if (target && targetCache) {
        delete target.editable;
        this.swonAreas = newSwonAreas;
        Object.assign(targetCache, target);
        this.cacheSwonAreas = newSwonAreasData;

        const body = new FormData();
        body.append('id', target.key);
        body.append('culture', target.culture);
        body.append('square', target.square);
        body.append('manufacturer', target.manufacturer);
        body.append('provider', target.provider);
        body.append('comment', target.comment);

        apiRequest('/object/square/edit', 'POST', body, true);
      }

      this.editingKey = '';
    },

    cancel(key) {
      const newSwonAreas = [...this.swonAreas];
      const target = newSwonAreas.filter((item) => key === item.key)[0];

      this.editingKey = '';

      if (target) {
        Object.assign(
          target,
          this.cacheSwonAreas.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.swonAreas = newSwonAreas;
      }
    },

    async addRow() {
      const body = new FormData();
      body.append('object_id', this.object.info.id);
      body.append('culture', '');
      body.append('square', '');
      body.append('manufacturer', '');
      body.append('provider', '');
      body.append('comment', '');

      this.addRowLoading = true;
      const res = await apiRequest('/object/square/add', 'POST', body, true);
      this.addRowLoading = false;

      const { square } = res;
      const { swonAreas } = this;
      const key = square.id;
      const newSwonAreas = {
        key,
        culture: square.culture,
        square: square.square,
        manufacturer: square.manufacturer,
        provider: square.provider,
        comment: square.comment,
      };

      this.swonAreas = [newSwonAreas, ...swonAreas];
      this.cacheSwonAreas = [newSwonAreas, ...swonAreas];
      this.edit(key);
    },

    async handleRemoveObject() {
      try {
        const {
          object: {
            info: { id },
          },
        } = this;
        const body = new FormData();

        body.append('id', id);

        this.deleteObjectLoading = true;
        const res = await apiRequest('/object/delete', 'POST', body, true);
        this.deleteObjectLoading = false;

        if (res.success) {
          this.$router.replace('/');
        }
      } catch (error) {
        console.log('handleRemoveObject', error);
      }
    },
  },
};
</script>

<style lang="scss">
.page-object-info {
  ul {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
  }

  li {
    + li {
      margin-top: 10px;
    }
  }
}

.page-object-info-contacts {
  ul {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.page-object-info-contacts-link {
  display: flex;
  align-items: center;
  font-size: 14px;

  .anticon {
    font-size: 20px;
    margin-right: 10px;
  }
}

.page-object-info-contacts-link-info {
  display: flex;
  flex-direction: column;

  small {
    color: #444545;
  }
}

.page-object-info-name {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
}

.page-object-info-comment {
  margin-top: 5px;
  font-size: 14px;
  color: #444545;
  max-width: 450px;
}

.swon-area-table {
  .ant-table-thead {
    th[key='culture'] {
      min-width: 175px;
    }

    th[key='square'] {
      min-width: 100px;
    }

    th[key='manufacturer'],
    th[key='provider'] {
      min-width: 175px;
    }

    th[key='comment'] {
      min-width: 200px;
    }

    th[key='operation'] {
      min-width: 80px;
    }
  }
}
</style>

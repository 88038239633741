<template>
  <div class="page page-object">
    <template v-if="object.info">
      <a-row>
        <a-col :lg="{ span: 18 }">
          <div class="page-object-info">
            <div class="page-object-info-name">{{ object.info.name }}</div>

            <a-row class="mt-25">
              <a-col :span="12">
                <div class="dashboard-list-item-title">Область:</div>
                <div class="dashboard-list-item-address">
                  {{
                    regions.find((region) => region.id == object.info.region)
                      .name
                  }}
                </div>
              </a-col>

              <a-col :span="12">
                <div class="dashboard-list-item-title">Район:</div>
                <div class="dashboard-list-item-address">
                  {{ object.info.district }}
                </div>
              </a-col>
            </a-row>

            <div class="object-layout-info-title">Адрес:</div>
            <div>
              {{ object.info.address }}
            </div>

            <template v-if="object.info.unp">
              <div class="object-layout-info-title">УНП:</div>
              <div>
                {{ object.info.unp }}
              </div>
            </template>

            <template v-if="object.info.swonareaText">
              <div class="object-layout-info-title">
                Посевные площади хозяйства (Га):
              </div>
              <div>
                {{ object.info.swonareaText }}
              </div>
            </template>

            <template>
              <div class="object-layout-info-title">Комментарий к объекту:</div>
              <div>
                {{
                  object.info.comment !== 'null' && object.info.comment
                    ? object.info.comment
                    : '-'
                }}
              </div>
            </template>

            <template v-if="object.contacts.length">
              <div class="object-layout-info-title">Контактные данные:</div>

              <a-row class="page-object-info-contacts" :gutter="[15, 20]">
                <a-col
                  v-for="(contact, index) in object.contacts"
                  :key="index"
                  :sm="{ span: 12 }"
                >
                  <ul>
                    <li v-if="contact.fio">
                      <div class="page-object-info-contacts-link">
                        <a-icon type="contacts" theme="filled"></a-icon>
                        <div class="page-object-info-contacts-link-info">
                          <span>{{ contact.fio }}</span>
                          <small>{{ contact.position }}</small>
                        </div>
                      </div>
                    </li>
                    <li v-if="contact.phone">
                      <a
                        :href="`tel:${contact.phone}`"
                        class="page-object-info-contacts-link"
                      >
                        <a-icon type="phone" theme="filled"></a-icon>
                        <div class="page-object-info-contacts-link-info">
                          <span>{{ contact.phone }}</span>
                        </div>
                      </a>
                    </li>
                    <li v-if="contact.email">
                      <a
                        :href="`mailto:${contact.email}`"
                        class="page-object-info-contacts-link"
                      >
                        <a-icon type="mail" theme="filled"></a-icon>
                        <div class="page-object-info-contacts-link-info">
                          <span>{{ contact.email }}</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </a-col>
              </a-row>
            </template>

            <a-row>
              <a-col v-if="object.managers.length" :sm="{ span: 12 }">
                <div>
                  <div class="object-layout-info-title">Менеджеры объекта:</div>
                  <ul>
                    <li v-for="manager in object.managers" :key="manager.id">
                      <user
                        :avatar="manager.avatar"
                        :title="`${manager.name} ${manager.lastName}`"
                      ></user>
                    </li>
                  </ul>
                </div>
              </a-col>

              <a-col v-if="object.directions.length" :sm="{ span: 12 }">
                <div>
                  <div class="object-layout-info-title">
                    Направления работы:
                  </div>
                  <ul>
                    <li
                      v-for="direction in object.directions"
                      :key="direction.id"
                    >
                      <a-avatar
                        :size="35"
                        icon="check"
                        style="backgroundcolor: #42af63"
                      >
                      </a-avatar>
                      <span class="ml-10">
                        {{ direction.name }}
                      </span>
                    </li>
                  </ul>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>

        <a-col :lg="{ span: 6 }">
          <div class="object-layout-actions">
            <a-button @click="toggleEditModal">Редактировать объект</a-button>

            <a-button @click="toggleActivitiModalVisible">
              Изменить статус
            </a-button>

            <a-popconfirm
              :title="`Вы уверены что хотите удалить объект?`"
              ok-text="Да"
              cancel-text="Нет"
              @confirm="handleRemoveObject"
            >
              <a-button type="danger" ghost>Удалить объект</a-button>
            </a-popconfirm>
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <div class="object-layout-info-title">
            Текущий статус объекта:

            <template v-if="object.historyStatus.length">
              <a-button
                v-if="userRole === 'admin'"
                size="small"
                class="ml-10"
                @click="editCurrentStatus"
              >
                Редактировать
              </a-button>

              <a-popconfirm
                title="Вы уверены что хотите удалить последнюю активность?"
                ok-text="Да"
                cancel-text="Нет"
                @confirm="handleDeleteLastActivity"
              >
                <a-button
                  v-if="userRole === 'admin'"
                  size="small"
                  class="ml-10"
                  type="danger"
                  ghost
                  :loading="deleteObjectLoading"
                >
                  Удалить
                </a-button>
              </a-popconfirm>
            </template>
          </div>

          <a-table
            :columns="columns"
            :data-source="object.lastStatus"
            :pagination="false"
            tableLayout="auto"
            class="object-status-table mt-10"
          >
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" :key="tag" :color="tagColor(tag)">
                {{
                  statuses.find((status) => status.id == tag).name.toUpperCase()
                }}
              </a-tag>
            </span>
          </a-table>
        </a-col>
      </a-row>

      <template>
        <edit-modal
          :visible="isEditModalVisible"
          @close="toggleEditModal"
        ></edit-modal>

        <activiti-modal
          :visible="isActivitiModalVisible"
          :activiti-modal-edit="activitiModalEdit"
          @close="toggleActivitiModalVisible(true)"
        ></activiti-modal>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import apiRequest from '../js/helpers/apiRequest';

import User from '../components/User.vue';
import ActivitiModal from '../components/ActivitiModal.vue';
import EditModal from '../components/EditModal.vue';

const columns = [
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'tags' },
  },
  {
    title: 'Менеджер',
    dataIndex: 'manager',
    key: 'manager',
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Действие',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'Направление',
    dataIndex: 'direction',
    key: 'direction',
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
];

export default {
  name: 'Object',

  components: {
    User,
    ActivitiModal,
    EditModal,
  },

  data() {
    return {
      columns,
      isEditModalVisible: false,
      isActivitiModalVisible: false,
      activitiModalEdit: false,
      deleteObjectLoading: false,
    };
  },

  computed: {
    ...mapState({
      userRole: (state) => state.user.info.role,
      object: (state) => state.objects.object,
      statuses: (state) => state.filters.statuses,
      regions: (state) => state.filters.regions,
      managers: (state) => state.managers.managers,
    }),
  },

  created() {
    const {
      $route: { query },
      object: { lastStatus },
    } = this;

    if (query.status) {
      this.isActivitiModalVisible = true;
    }

    if (lastStatus) {
      this.lastStatus = JSON.parse(JSON.stringify(lastStatus));
      this.cacheLastStatus = lastStatus.map((item) => ({ ...item }));
    }
  },

  methods: {
    editCurrentStatus() {
      this.activitiModalEdit = true;
      this.toggleActivitiModalVisible();
    },

    toggleEditModal() {
      this.isEditModalVisible = !this.isEditModalVisible;
    },

    toggleActivitiModalVisible(isEdit) {
      const { query, path } = this.$route;

      if (query.status) {
        this.$router.push(path);
      }

      if (isEdit && this.isActivitiModalVisible) {
        this.activitiModalEdit = false;
      }

      this.isActivitiModalVisible = !this.isActivitiModalVisible;
    },

    tagColor(id) {
      if (id == 0) {
        return '';
      } else if (id == 1) {
        return 'green';
      } else if (id == 2) {
        return 'blue';
      } else if (id == 3) {
        return 'red';
      } else if (id == 4) {
        return 'orange';
      } else if (id == 5) {
        return 'cyan';
      }
    },

    async handleDeleteLastActivity() {
      try {
        const {
          object: {
            info: { id },
          },
        } = this;
        const body = new FormData();

        body.append('object_id', id);
        await apiRequest('/object/history/delete', 'POST', body, true);
        this.getObjectInfo(id);
      } catch (error) {
        console.log('handleDeleteLastActivity', error);
      }
    },

    async handleRemoveObject() {
      try {
        const {
          object: {
            info: { id },
          },
        } = this;
        const body = new FormData();

        body.append('id', id);

        this.deleteObjectLoading = true;
        const res = await apiRequest('/object/delete', 'POST', body, true);
        this.deleteObjectLoading = false;

        if (res.success) {
          this.$router.replace('/');
        }
      } catch (error) {
        console.log('handleRemoveObject', error);
      }
    },

    ...mapActions({
      getObjectInfo: 'objects/getObjectInfo',
    }),
  },
};
</script>

<style lang="scss">
.page-object-info {
  ul {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
  }

  li {
    + li {
      margin-top: 10px;
    }
  }
}

.page-object-info-contacts {
  ul {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.page-object-info-contacts-link {
  display: flex;
  align-items: center;
  font-size: 14px;

  .anticon {
    font-size: 20px;
    margin-right: 10px;
  }
}

.page-object-info-contacts-link-info {
  display: flex;
  flex-direction: column;

  small {
    color: #444545;
  }
}

.page-object-info-name {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
}

.page-object-info-comment {
  margin-top: 5px;
  font-size: 14px;
  color: #444545;
  max-width: 450px;
}
</style>
